var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-upload",
    {
      class: {
        [_vm.className]: true,
        "has-image": _vm.value,
        "has-image-small": _vm.skin === "small",
      },
      attrs: {
        "show-file-list": false,
        "before-upload": _vm.beforeUpload,
        "on-change": _vm.handleUpload,
        drag: "",
        action: "#",
        accept: _vm.accept,
        disabled: _vm.disabled,
      },
    },
    [
      _vm.value
        ? _c("img", { staticClass: "img", attrs: { src: _vm.value } })
        : [
            _vm.skin === "small"
              ? _c("i", { staticClass: "el-icon-plus uploader-icon" })
              : [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v(" 将图片拖到此处，或 "),
                    _c("em", [_vm._v("点击上传")]),
                  ]),
                ],
          ],
      _c(
        "div",
        { staticClass: "el-upload__tip", attrs: { slot: "tip" }, slot: "tip" },
        [
          _vm._v(
            " 只能上传jpg/png/gif文件，且不超过" +
              _vm._s(_vm.fileSize ? _vm.fileSize : "3MB") +
              " "
          ),
          _vm.tipSize
            ? _c("span", [_vm._v("，图片尺寸：" + _vm._s(_vm.tipSize))])
            : _vm._e(),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }