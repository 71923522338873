<template>
  <el-dialog
    title="抽奖规则配置"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <TqTabs
      ref="tqTabs"
      v-model="prizeTabs"
      @change="handleTabChange"
      @click="handleTabClick"
      :currentTab="prizeTabName"
      :max="8"
      :editable="!used"
    />
    <template>
      <PrizeForm
        v-for="(item, index) in this.formList"
        ref="prizeForm"
        v-show="currentTabIndex === index"
        v-model="formList[index]"
        :used="used"
        :key="index"
        @handle-probability="handleProbability"
        @handle-name="handleName"
      />
    </template>
    <el-button type="primary" @click="submitHandle(1)">保存</el-button>
    <el-button @click="closeHandler">关闭</el-button>
  </el-dialog>
</template>

<script>
import TqTabs from "@/components/TqTabs";
import PrizeForm from "./PrizeForm";
import {
  getPrizeConfig,
  savePrizeConfig,
  updatePrizeConfig,
  deletePrizeConfig,
} from "@/api/luckDraw";
export default {
  components: { TqTabs, PrizeForm },
  data() {
    return {
      dialogVisible: false,
      currentTabIndex: 0,
      prizeTabs: [{ name: "奖项1" }],
      formList: [],
      uuid: "",
      used: false,
    };
  },
  computed: {
    prizeTabName() {
      console.log("this.prizeTabs", this.prizeTabs);
      console.log("this.currentTabIndex", this.currentTabIndex);
      return this.prizeTabs[this.currentTabIndex].name;
    },
  },
  watch: {
    prizeTabName: {
      handler() {
        const tqTabsRef = this.$refs["tqTabs"];
        if (tqTabsRef) {
          tqTabsRef.needVerify = true;
        }
      },
    },
  },
  methods: {
    initData(tabName) {
      return {
        name: tabName || "",
        position: "",
        prize_type: "",
        prize_data: "",
        probability: undefined,
        total: undefined,
        daily_number: undefined,
        earliest_winning_time: "",
      };
    },
    async handleEdit(uuid, used) {
      this.uuid = uuid;
      this.used = used;
      this.currentTabIndex = 0;
      const result = await getPrizeConfig(this.uuid);
      console.log("fetchData result = ", result);
      this.formList = result.data || [];
      this.handleData();
    },
    handleData() {
      if (this.formList?.length === 0) {
        this.prizeTabs = [{ name: "奖项1" }];
        this.formList = [this.initData()];
      } else {
        let tabs = [];
        this.formList?.forEach((item) => {
          tabs.push({ name: item.name });
        });
        this.prizeTabs = tabs;
      }

      this.$nextTick(() => {
        // 清除所有表单验证结果
        if (this.$refs["prizeForm"]) {
          for (const key in this.formList) {
            this.$refs["prizeForm"][key].clearValidate();
          }
        }
      });

      this.dialogVisible = true;
    },
    handleTabChange(i, v) {
      if (v.action === "add") {
        this.currentTabIndex = i;
        this.formList.push(this.initData(v.newTabName));
      } else {
        this.currentTabIndex = i;
        const delIndex = i + 1;
        this.formList.splice(delIndex, 1);
      }
    },
    handleTabClick(i) {
      this.currentTabIndex = i;
    },
    async submitHandle(val) {
      const verifyRes = await this.$refs["prizeForm"][this.currentTabIndex]
        .submitDataVerify(this.currentTabIndex)
        .then(
          (val) => {
            return val;
          },
          (err) => {
            return err;
          }
        );
      if (verifyRes) {
        const prizeConfig = this.formList[this.currentTabIndex];
        if (prizeConfig.uuid) {
          await updatePrizeConfig(this.uuid, prizeConfig.uuid, prizeConfig);
        } else {
          const resp = await savePrizeConfig(this.uuid, prizeConfig);
          this.formList[this.currentTabIndex]["uuid"] = resp.data.uuid;
        }
        if (val === 1) {
          this.$message({
            showClose: true,
            message: "保存成功",
            type: "success",
          });
          // this.dialogVisible = false;
        }
        return true;
      }
      return false;
    },
    async deleteHandle(val) {
      console.log(val);
      const prizeConfig = this.formList[val];
      if (prizeConfig.uuid) {
        await deletePrizeConfig(this.uuid, prizeConfig.uuid);
      }
      return true;
    },
    handleProbability(v) {
      // 自动计算中奖概率
      console.log("handleProbability v=", v);
      // 获取已设置中奖概率
    },
    handleName(v) {
      // 同步tab页名称与奖品名称一致
      this.prizeTabs[this.currentTabIndex].name = v;
    },
    beforeCloseHandler() {
      // 关闭dialog前,清空form数组
      this.formList = [];
      console.log("beforeCloseHandler");
    },
    closeHandler() {
      console.log("this.formList", this.formList);
      const noSavePrize = this.formList.find((val) => !val.uuid);
      console.log(noSavePrize);
      if (noSavePrize) {
        this.$confirm(`奖品配置：【${noSavePrize.name}】暂未保存, 是否继续页面?`, "提示", {
          confirmButtonText: "关闭",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.formList = [];
            this.dialogVisible = false;
          })
          .catch(() => {
            console.log("cancel");
          });
      } else {
        this.formList = [];
        this.dialogVisible = false;
      }
    },
  },
};
</script>

<style></style>
