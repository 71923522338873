var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      staticClass: "custom",
      attrs: {
        type: "card",
        editable: _vm.editable,
        "before-leave": _vm.beforeLeave,
      },
      on: { edit: _vm.handleTabsEdit, "tab-click": _vm.handleTabClick },
      model: {
        value: _vm.currentTab,
        callback: function ($$v) {
          _vm.currentTab = $$v
        },
        expression: "currentTab",
      },
    },
    _vm._l(_vm.value, function (item) {
      return _c("el-tab-pane", { key: item.name, attrs: { name: item.name } }, [
        _c("span", { attrs: { slot: "label" }, slot: "label" }, [
          _vm._v(_vm._s(item.name)),
        ]),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }