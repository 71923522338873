var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "抽奖规则配置",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "show-close": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("TqTabs", {
        ref: "tqTabs",
        attrs: { currentTab: _vm.prizeTabName, max: 8, editable: !_vm.used },
        on: { change: _vm.handleTabChange, click: _vm.handleTabClick },
        model: {
          value: _vm.prizeTabs,
          callback: function ($$v) {
            _vm.prizeTabs = $$v
          },
          expression: "prizeTabs",
        },
      }),
      _vm._l(this.formList, function (item, index) {
        return _c("PrizeForm", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentTabIndex === index,
              expression: "currentTabIndex === index",
            },
          ],
          key: index,
          ref: "prizeForm",
          refInFor: true,
          attrs: { used: _vm.used },
          on: {
            "handle-probability": _vm.handleProbability,
            "handle-name": _vm.handleName,
          },
          model: {
            value: _vm.formList[index],
            callback: function ($$v) {
              _vm.$set(_vm.formList, index, $$v)
            },
            expression: "formList[index]",
          },
        })
      }),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.submitHandle(1)
            },
          },
        },
        [_vm._v("保存")]
      ),
      _c("el-button", { on: { click: _vm.closeHandler } }, [_vm._v("关闭")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }