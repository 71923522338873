var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "jumpFrom", attrs: { model: _vm.value, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "跳转类型",
                prop: "type",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", disabled: _vm.disabled },
                  on: { change: _vm.handleChangeType },
                  model: {
                    value: _vm.value.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "type", $$v)
                    },
                    expression: "value.type",
                  },
                },
                _vm._l(_vm.typeOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.value.type === 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "跳转链接",
                    prop: "link",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "w500",
                    attrs: { disabled: _vm.disabled, type: "text" },
                    model: {
                      value: _vm.value.link,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "link", $$v)
                      },
                      expression: "value.link",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.value.type === 2
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "跳转课程",
                    prop: "courses",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.tqTableTransfer.handleShow(2)
                            },
                          },
                        },
                        [_vm._v("选择课程")]
                      ),
                      _c("span", { staticClass: "f-grey f13 ml10" }, [
                        _vm._v(
                          "已选择" + _vm._s(_vm.value.courses.length) + "个课程"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("TqTableTransfer", {
                    ref: "tqTableTransfer",
                    attrs: {
                      multiple: _vm.courseMultiple,
                      disabled: _vm.disabled,
                      limit: _vm.courseLimite,
                      "produc-type": _vm.producType,
                    },
                    model: {
                      value: _vm.value.courses,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "courses", $$v)
                      },
                      expression: "value.courses",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.value.type === 3
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "跳转分类",
                    prop: "classify",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-cascader", {
                    ref: "cusCascader",
                    staticClass: "w400",
                    attrs: {
                      disabled: _vm.disabled,
                      options: _vm.categoryOptions,
                      props: { checkStrictly: true },
                      clearable: "",
                    },
                    model: {
                      value: _vm.value.classify,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "classify", $$v)
                      },
                      expression: "value.classify",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }