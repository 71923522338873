var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: "抽奖规则配置", visible: _vm.dialogVisible },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "dataForm", attrs: { model: _vm.formData, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "抽奖名称",
                "label-width": _vm.formLabelWidth,
                prop: "name",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "360px" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.viewDisabled,
                      maxlength: "50",
                      placeholder: "请输入抽奖名称",
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "抽奖类型",
                "label-width": _vm.formLabelWidth,
                prop: "draw_type",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item w150",
                  attrs: {
                    clearable: "",
                    placeholder: "请选择抽奖类型",
                    disabled: _vm.viewDisabled,
                  },
                  model: {
                    value: _vm.formData.draw_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "draw_type", $$v)
                    },
                    expression: "formData.draw_type",
                  },
                },
                [
                  _c("el-option", {
                    key: 1,
                    attrs: { label: "九宫格", value: 1 },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "用户限制",
                "label-width": _vm.formLabelWidth,
                prop: "user_limit",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.viewDisabled },
                  model: {
                    value: _vm.formData.user_limit,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "user_limit", $$v)
                    },
                    expression: "formData.user_limit",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("新用户")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("所有用户")]),
                  _c("el-radio", { attrs: { label: 3 } }, [_vm._v("指定用户")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showUserListUpload
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "上传名单",
                    "label-width": _vm.formLabelWidth,
                    prop: "user_limit_urls",
                  },
                },
                [
                  _c("TqFileUpload", {
                    attrs: { limit: 1, "template-url": _vm.templateUrl },
                    model: {
                      value: _vm.formData.user_limit_urls,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "user_limit_urls", $$v)
                      },
                      expression: "formData.user_limit_urls",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.formData.user_limit !== 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "商品限制",
                    "label-width": _vm.formLabelWidth,
                    prop: "goods_limit",
                  },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { disabled: _vm.viewDisabled },
                      on: { change: _vm.handleGoodsChange },
                      model: {
                        value: _vm.formData.goods_limit,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "goods_limit", $$v)
                        },
                        expression: "formData.goods_limit",
                      },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: 1 } }, [
                        _vm._v("无限制"),
                      ]),
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            label: 2,
                            disabled: _vm.goodsCheckboxDisabled,
                          },
                        },
                        [_vm._v("按价格")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            label: 3,
                            disabled: _vm.goodsCheckboxDisabled,
                          },
                        },
                        [_vm._v("按支付时间")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            label: 4,
                            disabled: _vm.goodsCheckboxDisabled,
                          },
                        },
                        [_vm._v("支付指定课程")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.formData.goods_limit.indexOf(2) !== -1
            ? _c(
                "el-form-item",
                {
                  staticClass: "custom-form-item",
                  attrs: {
                    label: "价格区间",
                    "label-width": _vm.formLabelWidth,
                    required: "",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "price_range_start" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          "controls-position": "right",
                          precision: 2,
                          step: 0.01,
                          min: 0,
                          max: 999999,
                          disabled: _vm.viewDisabled,
                        },
                        model: {
                          value: _vm.formData.price_range_start,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "price_range_start", $$v)
                          },
                          expression: "formData.price_range_start",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v("   ~   "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "price_range_end" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          "controls-position": "right",
                          precision: 2,
                          step: 0.01,
                          min: 0,
                          max: 999999,
                          disabled: _vm.viewDisabled,
                        },
                        model: {
                          value: _vm.formData.price_range_end,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "price_range_end", $$v)
                          },
                          expression: "formData.price_range_end",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.formData.goods_limit.indexOf(3) !== -1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "下单时间",
                    prop: "time_range",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "上线日期",
                      "end-placeholder": "下线日期",
                      "default-time": _vm.defaultTime,
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      disabled: _vm.viewDisabled,
                    },
                    model: {
                      value: _vm.formData.time_range,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "time_range", $$v)
                      },
                      expression: "formData.time_range",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.formData.goods_limit.indexOf(4) !== -1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "指定课程选择",
                    "label-width": _vm.formLabelWidth,
                    required: "",
                    prop: "assign_courses",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.tqTableTransfer.handleShow(2)
                            },
                          },
                        },
                        [_vm._v("选择课程")]
                      ),
                      _c("span", { staticClass: "f-grey f13 ml10" }, [
                        _vm._v(
                          "已选择" +
                            _vm._s(_vm.formData.assign_courses.length) +
                            "个课程"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("TqTableTransfer", {
                    ref: "tqTableTransfer",
                    model: {
                      value: _vm.formData.assign_courses,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "assign_courses", $$v)
                      },
                      expression: "formData.assign_courses",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "固定综合中奖率",
                "label-width": _vm.formLabelWidth,
                required: "",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.viewDisabled },
                  model: {
                    value: _vm.formData.is_lock_rate,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "is_lock_rate", $$v)
                    },
                    expression: "formData.is_lock_rate",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("固定")]),
                  _c("el-radio", { attrs: { label: false } }, [
                    _vm._v("不固定"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "custom-form-item",
              attrs: {
                label: "抽奖频率",
                "label-width": _vm.formLabelWidth,
                required: "",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "frequency_type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item w150",
                      attrs: {
                        clearable: "",
                        placeholder: "请选择抽奖频率",
                        disabled: _vm.viewDisabled,
                      },
                      model: {
                        value: _vm.formData.frequency_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "frequency_type", $$v)
                        },
                        expression: "formData.frequency_type",
                      },
                    },
                    [
                      _c("el-option", {
                        key: 1,
                        attrs: { label: "每天", value: 1 },
                      }),
                      _c("el-option", {
                        key: 2,
                        attrs: { label: "每周", value: 2 },
                      }),
                      _c("el-option", {
                        key: 3,
                        attrs: { label: "每月", value: 3 },
                      }),
                      _c("el-option", {
                        key: 4,
                        attrs: { label: "活动期间", value: 4 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v("    "),
              _c(
                "el-form-item",
                { attrs: { label: "参与次数", prop: "frequency_number" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      "controls-position": "right",
                      min: 1,
                      max: 99999,
                      disabled: _vm.viewDisabled,
                    },
                    model: {
                      value: _vm.formData.frequency_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "frequency_number", $$v)
                      },
                      expression: "formData.frequency_number",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v("未满足抽奖条件的跳转目标配置"),
          ]),
          _c("TqJumpType", {
            ref: "tqJumpType",
            attrs: {
              disabled: _vm.viewDisabled,
              "course-multiple": false,
              "course-limite": 1,
            },
            model: {
              value: _vm.formData.jump_data,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "jump_data", $$v)
              },
              expression: "formData.jump_data",
            },
          }),
          _c(
            "el-form-item",
            { staticStyle: { "text-align": "center" } },
            [
              !_vm.viewDisabled
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.submit } },
                    [_vm._v("保 存")]
                  )
                : _vm._e(),
              _vm.viewDisabled
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("关 闭")]
                  )
                : _vm._e(),
              !_vm.viewDisabled
                ? _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }