var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("SearchForm", {
        on: { search: _vm.fetchData },
        model: {
          value: _vm.queryParams,
          callback: function ($$v) {
            _vm.queryParams = $$v
          },
          expression: "queryParams",
        },
      }),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: ["ld.add_ld"],
              expression: "['ld.add_ld']",
            },
          ],
          staticStyle: { "margin-bottom": "10px" },
          attrs: { type: "primary", icon: "el-icon-plus" },
          on: {
            click: function ($event) {
              return _vm.$refs.ruleConfig.handleEdit()
            },
          },
        },
        [_vm._v("添加")]
      ),
      _c(
        "el-card",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading.body",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                  modifiers: { body: true },
                },
              ],
              attrs: {
                data: _vm.tableData,
                "element-loading-text": "加载中 ...",
                border: "",
                "header-align": "center",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "抽奖ID",
                  "min-width": "80px",
                  prop: "config_id",
                  align: "center",
                  "header-align": "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "抽奖名称",
                  "min-width": "200",
                  prop: "name",
                  align: "center",
                  "header-align": "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "抽奖类型",
                  "min-width": "200",
                  prop: "draw_type",
                  align: "center",
                  "header-align": "center",
                  formatter: _vm.typeFormatter,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "是否有效",
                  "min-width": "120px",
                  prop: "effective",
                  align: "center",
                  "header-align": "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("TqSwitch", {
                          attrs: {
                            confirm: "",
                            tip: `是否使配置${
                              scope.row.is_active ? "失效" : "有效"
                            }`,
                            disabled: !_vm.permission,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.switchEnableHandle(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.is_active,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "is_active", $$v)
                            },
                            expression: "scope.row.is_active",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "是否引用",
                  "min-width": "120px",
                  prop: "used",
                  align: "center",
                  "header-align": "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.used
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("是"),
                            ])
                          : _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("否"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  "min-width": "310",
                  align: "center",
                  formatter: _vm.timeFormatter,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建人",
                  "min-width": "80px",
                  prop: "creator",
                  align: "center",
                  "header-align": "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "300px",
                  align: "center",
                  "header-align": "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.used
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["ld.view_ld"],
                                    expression: "['ld.view_ld']",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.ruleConfig.handleEdit(
                                      scope.row.uuid
                                    )
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["ld.modify_ld"],
                                    expression: "['ld.modify_ld']",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.ruleConfig.handleEdit(
                                      scope.row.uuid
                                    )
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["ld.manage_ldi"],
                                expression: "['ld.manage_ldi']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.prizeConfig.handleEdit(
                                  scope.row.uuid,
                                  scope.row.used
                                )
                              },
                            },
                          },
                          [_vm._v("奖项配置")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["ld.copy_ld"],
                                expression: "['ld.copy_ld']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleCopy(scope.row)
                              },
                            },
                          },
                          [_vm._v("复制")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination-container" },
            [
              _c("pagination", {
                attrs: {
                  total: _vm.count,
                  page: _vm.queryParams.page,
                  limit: _vm.queryParams.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "size", $event)
                  },
                  pagination: _vm.fetchData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("RuleConfig", { ref: "ruleConfig" }),
      _c("PrizeConfig", { ref: "prizeConfig" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }