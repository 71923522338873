var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: `选择${_vm.title}`,
        visible: _vm.dialogVisible,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "table-transfer" },
        [
          _c("table-col", {
            ref: "left",
            attrs: {
              title: `所有${_vm.title}`,
              value: _vm.unselectedArr,
              "data-type": _vm.dataType,
              loading: _vm.loading,
              multiple: _vm.multiple,
              total: _vm.total,
            },
            on: { "fetch-data": _vm.getUnselectedData },
          }),
          _c("div", [
            _c(
              "p",
              [
                _c("el-button", {
                  attrs: { icon: "el-icon-arrow-right", circle: "" },
                  on: { click: _vm.addHandler },
                }),
              ],
              1
            ),
            _c(
              "p",
              [
                _c("el-button", {
                  attrs: { icon: "el-icon-arrow-left", circle: "" },
                  on: { click: _vm.removeHandler },
                }),
              ],
              1
            ),
          ]),
          _c("table-col", {
            ref: "right",
            attrs: {
              title: `已选${_vm.title}`,
              value: _vm.selectedDataArr,
              "table-type": 2,
              "data-type": _vm.dataType,
              multiple: _vm.multiple,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }