var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-upload",
    {
      staticClass: "upload-demo",
      attrs: {
        action: "",
        "on-remove": _vm.handleRemove,
        "on-success": _vm.handleSuccess,
        "before-remove": _vm.beforeRemove,
        multiple: "",
        limit: _vm.limit,
        "on-exceed": _vm.handleExceed,
        "http-request": _vm.customUpload,
        "file-list": _vm.fileList,
        disabled: _vm.disabled,
      },
    },
    [
      _c(
        "el-button",
        {
          attrs: {
            disabled: _vm.disabled,
            size: "mini",
            type: "primary",
            plain: "",
            icon: "el-icon-upload2",
          },
        },
        [_vm._v("点击上传")]
      ),
      _c(
        "el-button",
        {
          attrs: {
            disabled: _vm.disabled,
            size: "mini",
            type: "success",
            plain: "",
            icon: "el-icon-download",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.downloadHandler.apply(null, arguments)
            },
          },
        },
        [_vm._v("模板下载")]
      ),
      _vm.tip
        ? _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [_vm._v("只能上传jpg/png文件，且不超过500kb")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }